import * as React from "react";
import { Link } from "gatsby";

// React
import { useState, useEffect } from "react";
import { AdvancedImage, responsive } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";

// SVG
import Logo from "../../images/logo/logo.inline.svg";
import LogoLetters from "../../images/logo/logo-letters.inline.svg";

// Heroicons
import { ArrowNarrowDownIcon, CheckIcon } from "@heroicons/react/outline";

// Shared Components
import TextModal from "../../components/shared/modal/Modal";
import LoadingButton from "../../components/shared/loading-button/LoadingButton";
import Footer from "../../components/shared/footer/Footer";
import Header from "../../components/shared/header/Header";
import { Controller, Scene } from "react-scrollmagic";
import Seo from "../../components/seo";

// Services
import { contactSubmission } from "../../services/contactAPI";

// Animation
import ModuleAnimation from "../../images/animation/ModuleAnimation";

const pricing = {
  tiers: [
    {
      title: "Individual",
      frequency: "/month",
      features: [
        "Unlimited 1-on-1 guidance with a certified ARITAE coach",
        "24/7 access via ARITAE Chat",
        "Exclusive access to the global ARITAE Community",
        "Gaining critical skills for that are necessary for growth",
      ],
      cta: "INDIVIDUAL PRICING",
      mostPopular: false,
    },
    {
      title: "Group",
      frequency: "/month",
      features: [
        "Unlimited 1-on-1 guidance with a certified ARITAE coach",
        "24/7 access via ARITAE Chat",
        "Exclusive access to the global ARITAE Community",
        "Gaining critical skills for that are necessary for growth",
        "Access for your team up to twelve players",
      ],
      cta: "GROUP PRICING",
      mostPopular: false,
    },
  ],
};

export default function Parents() {
  const [largeScreen, setLargeScreen] = useState(true);
  const [durationScrollMagic, setDurationScrollMagic] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isJerryOpen, setIsJerryOpen] = useState(false);
  const [icon, setIcon] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  function checkScreenSize() {
    if (window.innerWidth <= 768) {
      setLargeScreen(false);
      setDurationScrollMagic(0);
    } else {
      setLargeScreen(true);
      setDurationScrollMagic(700);
    }
  }

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  // Create a Cloudinary instance and set your cloud name.
  const cld = new Cloudinary({
    cloud: {
      cloudName: "kirkwork",
    },
  });

  // define our cloudinary images
  const Orchestra = cld
    .image("v1646075391/Aritae/Parents/parents-header-orchestra_wr86ex.jpg")
    .quality("auto");
  const OrchestraMobile = cld
    .image(
      "v1646075391/Aritae/Parents/parents-header-orchestra-mobile_s1iyby.jpg"
    )
    .quality("auto");
  const TeenagerTouchdown = cld
    .image(
      "v1650478018/Aritae/Parents/teenager-in-football-gear-getting-ready-to-score-touchdown_dpspxv.jpg"
    )
    .quality("auto");
  const TeenagerTouchdownMobile = cld
    .image(
      "v1650478018/Aritae/Parents/teenager-in-football-gear-getting-ready-to-score-touchdown-small_xjidhy.jpg"
    )
    .quality("auto");
  const Richard = cld
    .image("v1652727914/Aritae/Parents/hockey-player-tall_h45lyw.jpg")
    .quality("auto");
  const RichardMobile = cld
    .image("v1652727914/Aritae/Parents/hockey-player-long_ja2iw8.jpg")
    .quality("auto");
  const Julie = cld
    .image("v1652727914/Aritae/Parents/student-tall_vdqaw1.jpg")
    .quality("auto");
  const JulieMobile = cld
    .image("v1652727914/Aritae/Parents/student-long_fom7nw.jpg")
    .quality("auto");
  const Laptop = cld
    .image(
      "v1654788748/Aritae/Parents/ARITAE-parent-recieving_progress_giguty.jpg"
    )
    .quality("auto");
  const Motorcycle = cld
    .image(
      "v1650481030/Aritae/Parents/aritae-motorcycle-rider-testimonia-positive_dl6j8g.jpg"
    )
    .quality("auto");
  const MotorcycleMobile = cld
    .image(
      "v1650481030/Aritae/Parents/motorcycle-rider-aritae-testimonial-positive-long_fvt99l.jpg"
    )
    .quality("auto");
  const App = cld
    .image(
      "v1649690327/Aritae/Parents/coaches-and-players-taking-with-eachother-on-the-aritae-mobile-app_buwz7s.jpg"
    )
    .quality("auto");

  //email sent from contact form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const email = event.target.email.value;

    let postBody = JSON.stringify({
      Email: email,
      PageName: "Parents",
    });

    contactSubmission(postBody).then(async (response) => {
      const data = await response;
      console.log(data);
      if (data.dbSuccess === true && data.endUserEmailSuccess === true) {
        // modal success
        setIsOpen(true);
        setIcon("success");
        setDescription(
          "Thanks for your interest in ARITAE! Please check your inbox for updates."
        );
        setLoading(false);
        event.target.reset();
      } else {
        // modal error
        setIsOpen(true);
        setIcon("failure");
        setDescription("Something went wrong. Please try again.");
        setLoading(false);
        event.target.reset();
      }
    });
  };

  // Modal
  function toggleModalOpen() {
    setIsOpen(!isOpen);
  }

  // Second Modal
  function toggleJerryModalOpen() {
    setIsJerryOpen(!isJerryOpen);
  }

  return (
    <div id="root">
      <Header />
      <Seo
        title="Parents"
        description="Learn what ARITAE Self-Leadership Academy can do for your child."
      />
      <div className="relative">
        {/* Modal */}
        <TextModal
          isOpen={isOpen}
          close={toggleModalOpen}
          icon={icon}
          description={description}
        />
        <Controller>
          <div className="mx-auto">
            {/* START HERO SECTION */}
            <div className="h-screen relative">
              <div className="absolute inset-0">
                <div className="absolute flex-1 top-0 inset-0">
                  <div className={"absolute inset-0 opacity-40 bg-stone-500"} />
                  <AdvancedImage
                    plugins={[responsive({ steps: 200 })]}
                    cldImg={largeScreen ? Orchestra : OrchestraMobile}
                    alt="orchestra performing with conductor using sheet music with aritae students performing"
                    className="h-full w-full object-cover"
                  />
                </div>
              </div>
              <div className="relative">
                <div className="flex flex-col h-screen items-center justify-center">
                  <h1 className="text-center text-4xl font-extrabold sm:text-5xl lg:text-6xl">
                    <span className="block text-white text-2xl pb-6">
                      PARENTS
                    </span>
                  </h1>
                  <div
                    className={
                      "flex flex-col items-center justify-center my-12"
                    }
                  >
                    <Logo
                      fill={"white"}
                      className={"h-32 w-auto mx-auto pb-2"}
                    />
                    <LogoLetters
                      fill={"white"}
                      className={"h-auto w-48 mx-auto"}
                    />
                  </div>
                  <p
                    className={"text-white text-center font-bold text-4xl mt-4"}
                  >
                    CREATE A MORE INSPIRED WORLD
                  </p>
                  <div className="hidden lg:flex mt-10 max-w-sm mx-auto justify-center">
                    <ArrowNarrowDownIcon className={"h-7 text-white"} />
                  </div>
                </div>
              </div>
            </div>
            {/* END HERO SECTION */}

            {/* START ACADEMY Section */}
            <div className="py-12 md:pt-24 md:pb-20 mx-auto px-4 md:px-16 max-w-7xl">
              <div>
                <h2
                  className={
                    "text-slate-600 text-center text-4xl px-12 pb-12 pt-24"
                  }
                >
                  WHAT IS THE ARITAE SELF-LEADERSHIP <b>ACADEMY</b>?
                </h2>
                <p
                  className={
                    "text-center text-xl px-12 pb-24 md:px-18 lg:px-48"
                  }
                >
                  We believe that every young person has the potential to be an
                  asset in this world and that the need to invest in this “human
                  potential” is immeasurable. We take a proactive approach to
                  personal growth by utilizing the ARITAE program and the ARITAE
                  Coach to guide young people to find the clarity, focus, and
                  power needed to “unleash” their potential, become
                  self-leaders, and live lives filled with the level of love,
                  happiness, significance, and success that they desire.
                </p>
              </div>
              <div className={"relative mx-12"}>
                <div
                  className={
                    "relative mx-auto grid grid-cols-1 lg:grid-cols-3 gap-10 p-4 md:p-10 max-w-9xl"
                  }
                >
                  <div
                    className={
                      "relative max-w-sm md:max-w-lg lg:max-w-none mx-auto"
                    }
                  >
                    <div className={"flex justify-center"}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50.74"
                        viewBox="0 0 100 100.74"
                      >
                        <g id="layer1" transform="translate(-0.758 -280.285)">
                          <path
                            id="path8136"
                            d="M90.758,280.288a1.588,1.588,0,0,0-.459.082l-9.582,3.1a1.678,1.678,0,0,0-1.072,2.117l.514,1.6L24.277,305.24a1.678,1.678,0,0,0-1.078,2.117l.514,1.593-12.774,4.129-.514-1.593a1.483,1.483,0,0,0-.052-.164,1.678,1.678,0,0,0-2.055-.924l-6.39,2.065a1.678,1.678,0,0,0-1.085,2.124l6.19,19.151a1.678,1.678,0,0,0,2.124,1.085l6.387-2.068a1.678,1.678,0,0,0,1.072-2.117l-.514-1.589,12.774-4.129.518,1.6a1.678,1.678,0,0,0,2.117,1.078l15.278-4.942a11.015,11.015,0,0,0,7.436,5.879L34.931,378.689a1.678,1.678,0,1,0,3.133,1.2l9.267-24.09h7.626v23.483a1.678,1.678,0,1,0,3.356,0V355.8c2.5,0,5.055.006,7.54-.019L75.2,379.885a1.68,1.68,0,1,0,3.133-1.212l-19.42-50.109a10.875,10.875,0,0,0,8.533-12.594l19.944-6.439.514,1.59a1.678,1.678,0,0,0,2.11,1.091l9.579-3.1a1.678,1.678,0,0,0,1.085-2.12L92.42,281.453a1.678,1.678,0,0,0-1.661-1.167Zm-1.016,3.792,7.226,22.356L90.581,308.5l-7.229-22.356Zm-8.553,6.3,5.165,15.966L66.374,312.8a10.967,10.967,0,0,0-20.717,5.04,10.9,10.9,0,0,0,.131,1.606L32.066,323.88q-2.573-7.984-5.152-15.966ZM56.628,310.208a7.622,7.622,0,1,1-7.616,7.629A7.607,7.607,0,0,1,56.628,310.208Zm-31.882,1.933,3.1,9.582-12.774,4.129-3.1-9.579Zm31.882.58a5.109,5.109,0,1,0,5.1,5.116A5.136,5.136,0,0,0,56.628,312.722Zm-48.88,1.39c1.883,5.823,3.469,10.732,5.161,15.969l-3.2,1.032L4.552,315.143Zm48.88,1.966a1.753,1.753,0,1,1-1.747,1.76A1.73,1.73,0,0,1,56.628,316.077Zm-1.671,19.895v16.47H48.622Zm3.356.354,6.246,16.116H58.312Z"
                            transform="translate(0 0)"
                            fill="#434f5b"
                          />
                        </g>
                      </svg>
                    </div>
                    <div
                      className={"relative pt-2 flex flex-col justify-between"}
                    >
                      <div className="text-center">
                        <h4
                          className={
                            "text-mustard-light text-xl font-bold text-center pt-4"
                          }
                        >
                          DISCOVERY
                        </h4>
                        <p className={"text-md text-center"}>
                          Through a process of self-reflection, players will
                          gain clarity, focus, and personal power.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      "relative max-w-sm md:max-w-lg lg:max-w-none mx-auto"
                    }
                  >
                    <div className={"flex justify-center"}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        viewBox="0 0 100.002 100"
                      >
                        <g id="Page-1" transform="translate(0.001)">
                          <g
                            id="_047---Mountain-Route"
                            data-name="047---Mountain-Route"
                            transform="translate(-0.001)"
                          >
                            <path
                              id="Shape"
                              d="M56.226,16.667A3.343,3.343,0,0,0,53.351,15h-.683V11.2a3.322,3.322,0,0,0,1.667-2.87V5a3.322,3.322,0,0,0-1.667-2.87V1.667a1.667,1.667,0,1,0-3.333,0h-7.5a2.5,2.5,0,0,0-1.767,4.268l.733.732-.733.732a2.5,2.5,0,0,0,1.767,4.268h7.5V15h-.683a3.35,3.35,0,0,0-2.883,1.678L43.8,20.142a4.987,4.987,0,0,0-4.713,8.333L1.439,95.012A3.333,3.333,0,0,0,4.322,100H97.679a3.333,3.333,0,0,0,2.883-4.987ZM43.842,5H51V8.333H43.834A2.5,2.5,0,0,0,43.842,5Zm9.492,13.32,12.788,22.6-2.365,3.045-3.133-3.917a2.968,2.968,0,0,0-2.48-1.142,3.073,3.073,0,0,0-2.417,1.28l-.76,1.067-7.98-13.778a4.868,4.868,0,0,0-.382-5.517l2.045-3.622Zm-2.08,28.145-4.645-7.438a3.072,3.072,0,0,0-5-.283L38.5,42.635,36.334,40.1l5.75-10.167a5.052,5.052,0,0,0,.583.063,4.953,4.953,0,0,0,1.742-.333L52.844,44.23ZM42.667,23.333A1.667,1.667,0,1,1,41,25,1.667,1.667,0,0,1,42.667,23.333ZM34.594,43.19l1.585,1.855a3,3,0,0,0,2.368,1.078,3.058,3.058,0,0,0,2.355-1.15l3.083-3.855,4.648,7.445A3.06,3.06,0,0,0,51.134,50h.1a3.067,3.067,0,0,0,2.5-1.287l.937-1.315L57.2,51.773a4.958,4.958,0,0,0-1.178,2.913L41.721,58.77a5,5,0,1,0-1.247,7.023L57.892,78.572c-.038.132-.092.255-.12.392L42.1,81.067a4.985,4.985,0,0,0-9.432,2.267,4.918,4.918,0,0,0,.5,2.142L21.986,96.657l-17.652,0ZM61,53.333A1.667,1.667,0,1,1,59.334,55,1.667,1.667,0,0,1,61,53.333ZM39.334,61.667A1.667,1.667,0,1,1,37.667,60,1.667,1.667,0,0,1,39.334,61.667ZM36,83.333A1.667,1.667,0,1,1,37.667,85,1.667,1.667,0,0,1,36,83.333ZM26.7,96.657l8.833-8.833a4.918,4.918,0,0,0,2.133.51,5,5,0,0,0,4.888-3.965l15.68-2.1a4.987,4.987,0,1,0,1.618-6.395L42.436,63.093a5.029,5.029,0,0,0,.2-1.113l14.3-4.085a5.013,5.013,0,1,0,3.147-7.8L56.8,44.425l1.438-2.02,3.117,3.9a3.027,3.027,0,0,0,2.392,1.167,3.075,3.075,0,0,0,2.43-1.183L67.9,44.072,97.679,96.667ZM61,80a1.667,1.667,0,1,1,1.667,1.667A1.667,1.667,0,0,1,61,80Z"
                              transform="translate(-0.999 0)"
                              fill="#434f5b"
                            />
                            <path
                              id="Shape-2"
                              data-name="Shape"
                              d="M58.168,65.205a1.664,1.664,0,1,0,2.88-1.667L48.382,41.819a1.667,1.667,0,1,0-2.877,1.68Z"
                              transform="translate(28.5 27.295)"
                              fill="#434f5b"
                            />
                            <circle
                              id="Oval"
                              cx="1.667"
                              cy="1.667"
                              r="1.667"
                              transform="translate(70.43 62.702)"
                              fill="#434f5b"
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div
                      className={"relative pt-2 flex flex-col justify-between"}
                    >
                      <div>
                        <h4
                          className={
                            "text-mustard-light text-xl font-bold text-center pt-4"
                          }
                        >
                          STRATEGY
                        </h4>
                        <p className={"text-md text-center"}>
                          Players will create a final plan that will give them
                          the skills, tools, and confidence to unleash their
                          potential.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      "relative lg:block max-w-sm md:max-w-lg lg:max-w-none mx-auto"
                    }
                  >
                    <div className={"flex justify-center"}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        viewBox="0 0 100 100"
                      >
                        <g id="finish-line" transform="translate(-1 -1)">
                          <path
                            id="Path_593"
                            data-name="Path 593"
                            d="M99.387,30.032A1.612,1.612,0,0,0,101,28.419V2.613A1.612,1.612,0,0,0,99.387,1H2.613A1.612,1.612,0,0,0,1,2.613V28.419a1.612,1.612,0,0,0,1.613,1.613H4.226v61.29H2.613A1.612,1.612,0,0,0,1,92.935v6.452A1.612,1.612,0,0,0,2.613,101h12.9a1.612,1.612,0,0,0,1.613-1.613V92.935a1.612,1.612,0,0,0-1.613-1.613H13.9V75.194H39.71V96.161a4.839,4.839,0,1,0,9.677,0v-12.9a1.613,1.613,0,1,1,3.226,0v12.9a4.839,4.839,0,1,0,9.677,0V75.194H88.1V91.323H86.484a1.612,1.612,0,0,0-1.613,1.613v6.452A1.612,1.612,0,0,0,86.484,101h12.9A1.612,1.612,0,0,0,101,99.387V92.935a1.612,1.612,0,0,0-1.613-1.613H97.774V30.032ZM4.226,4.226H97.774V26.806H4.226ZM88.1,65.516H62.29v-7.4L72.3,48.108a4.332,4.332,0,0,0,1.279-3.087v-.945a4.367,4.367,0,0,0-4.366-4.366h-.945a4.341,4.341,0,0,0-3.087,1.279L60.873,45.3A14.037,14.037,0,0,1,58.6,47.134a8.065,8.065,0,1,0-15.2,0A13.968,13.968,0,0,1,41.127,45.3l-4.31-4.308a4.341,4.341,0,0,0-3.087-1.279h-.945a4.367,4.367,0,0,0-4.366,4.366v.945A4.332,4.332,0,0,0,29.7,48.108L39.71,58.119v7.4H13.9V30.032H88.1Zm-45.634-9.2L31.979,45.827a1.131,1.131,0,0,1-.334-.806v-.945a1.14,1.14,0,0,1,1.14-1.14h.945a1.14,1.14,0,0,1,.806.334l4.31,4.308a17.184,17.184,0,0,0,24.306,0l4.31-4.308a1.14,1.14,0,0,1,.806-.334h.945a1.14,1.14,0,0,1,1.14,1.14v.945a1.131,1.131,0,0,1-.334.806L59.537,56.311a1.609,1.609,0,0,0-.473,1.14v8.065H42.935V57.452A1.609,1.609,0,0,0,42.463,56.311Zm3.7-11.763A4.839,4.839,0,1,1,51,49.387,4.845,4.845,0,0,1,46.161,44.548ZM13.9,97.774H4.226V94.548H13.9ZM7.452,91.323V30.032h3.226v61.29Zm51.613,4.839a1.613,1.613,0,1,1-3.226,0v-12.9a4.839,4.839,0,1,0-9.677,0v12.9a1.613,1.613,0,1,1-3.226,0V75.194H59.065ZM13.9,71.968V68.742H88.1v3.226ZM97.774,97.774H88.1V94.548h9.677Zm-3.226-6.452H91.323V30.032h3.226Z"
                            fill="#434f5b"
                          />
                          <path
                            id="Path_594"
                            data-name="Path 594"
                            d="M13,6.613V21.129h3.226V14.677h6.452V11.452H16.226V8.226h6.452V5H14.613A1.612,1.612,0,0,0,13,6.613Z"
                            transform="translate(7.355 2.452)"
                            fill="#434f5b"
                          />
                          <path
                            id="Path_595"
                            data-name="Path 595"
                            d="M21,5h3.226V21.129H21Z"
                            transform="translate(12.258 2.452)"
                            fill="#434f5b"
                          />
                          <path
                            id="Path_596"
                            data-name="Path 596"
                            d="M31.452,12.684l-3.4-6.792A1.614,1.614,0,0,0,25,6.613V21.129h3.226V13.445l3.4,6.792a1.614,1.614,0,0,0,3.056-.721V5H31.452Z"
                            transform="translate(14.71 2.452)"
                            fill="#434f5b"
                          />
                          <path
                            id="Path_597"
                            data-name="Path 597"
                            d="M33,5h3.226V21.129H33Z"
                            transform="translate(19.613 2.452)"
                            fill="#434f5b"
                          />
                          <path
                            id="Path_598"
                            data-name="Path 598"
                            d="M37,9.839a4.845,4.845,0,0,0,4.839,4.839,1.613,1.613,0,1,1,0,3.226H37v3.226h4.839a4.839,4.839,0,0,0,0-9.677,1.613,1.613,0,0,1,0-3.226h4.839V5H41.839A4.845,4.845,0,0,0,37,9.839Z"
                            transform="translate(22.065 2.452)"
                            fill="#434f5b"
                          />
                          <path
                            id="Path_599"
                            data-name="Path 599"
                            d="M51.452,11.452H48.226V5H45V21.129h3.226V14.677h3.226v6.452h3.226V5H51.452Z"
                            transform="translate(26.968 2.452)"
                            fill="#434f5b"
                          />
                        </g>
                      </svg>
                    </div>
                    <div
                      className={"relative pt-2 flex flex-col justify-between"}
                    >
                      <div>
                        <h4
                          className={
                            "text-mustard-light text-xl font-bold text-center pt-4"
                          }
                        >
                          EXECUTION
                        </h4>
                        <p className={"text-md text-center"}>
                          With the guidance from their ARITAE Coach, players
                          will be held accountable so they can successfully
                          execute their plans.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* END Academy Section */}

            {/* START Jerry Section */}
            <div className="">
              <div className={"max-w-7xl mx-auto py-6"}>
                <div
                  className={
                    "relative grid grid-cols-1 lg:grid-cols-3 gap-3 p-12"
                  }
                >
                  {/* First section */}
                  <div
                    className={
                      "relative flex justify-center col-span-2 items-center md:py-10"
                    }
                  >
                    <div className={"text-white"}>
                      <div className="shadow p-6 md:p-16 overflow-clip rounded-sm bg-slate-600 max-w-xl md:max-w-xl lg:max-w-none mx-auto">
                        <h4 className="text-4xl text-white mb-6">
                          DEAR ARITAE <b>PARENTS</b>,
                        </h4>
                        <p className="font-serif text-center text-md text-white mb-8">
                          I have spent over 35 years coaching kids and young
                          adults (females, as well as males) in ice hockey,
                          baseball, football, and soccer. Over this time period,
                          I was consistently saddened, disappointed, and
                          concerned by the fact that, although the student
                          athlete may or may not have been unleashing their
                          potential on the field, court, or ice surface, many
                          struggled in other areas of their lives, in social
                          settings with friends and significant others; with
                          academics; at home with parents or siblings; at work
                          or in other areas. It became very evident that they
                          lacked the self-leadership skills to effectively
                          understand how to reach their potential in all areas
                          of their lives.{" "}
                        </p>
                        <div className="flex justify-center font-bold underline underline-offset-4 decoration-2 underline-white">
                          <button
                            onClick={toggleJerryModalOpen}
                            className="cursor-pointer"
                          >
                            More About Our Founder
                          </button>
                        </div>
                        {/* Modal */}
                        <TextModal
                          isOpen={isJerryOpen}
                          title={"JERRY MORRISSEY"}
                          close={toggleJerryModalOpen}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Second section */}
                  <div className={"relative py-10 w-full"}>
                    <div
                      className={
                        "relative w-full justify-center items-center max-w-sm md:max-w-xl lg:max-w-none mx-auto"
                      }
                    >
                      <video
                        autoPlay={false}
                        controls
                        playsInline
                        preload="none"
                        poster="https://res.cloudinary.com/kirkwork/video/upload/q_auto/v1646225582/Aritae/Shared/Jerry-Placeholder-Mediu_wftrnf.jpg"
                        style={{ height: "100%", width: "100%" }}
                      >
                        {" "}
                        <source
                          src="https://res.cloudinary.com/kirkwork/video/upload/q_auto/v1646225582/Aritae/Shared/Jerry-Placeholder-Mediu_wftrnf.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <div className="w-full">
                        <p className="text-slate-600 mt-2 text-sm font-bold">
                          JERRY MORRISSEY
                        </p>
                        <p className="text-mustard text-md font-bold">
                          FOUNDER, CEO & CHIEF INSPIRATIONAL OFFICER
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* END Jerry Section */}

            {/* START Testimonials Section */}
            <div className="">
              <div className={"relative py-24 px-16"}>
                <div className={"pb-12"}>
                  <div className={"flex items-center"}>
                    <h1
                      className={
                        "mx-auto text-slate-600 max-w-xl text-center text-4xl"
                      }
                    >
                      <span className="font-extrabold">TESTIMONIALS</span>
                    </h1>
                  </div>
                  {/* Link to testimnoials */}
                  <div className={"flex flex-row justify-center gap-10"}>
                    <Link
                      to="/testimonials"
                      className={
                        "flex flex-row items-center pt-4 gap-5 hover:gap-3"
                      }
                    >
                      <p
                        className={
                          "text-slate-600 font-bold underline underline-offset-4 decoration-2 decoration-slate-600 hover:underline-offset-2"
                        }
                      >
                        More Stories That Will Inspire You
                      </p>
                    </Link>
                  </div>
                </div>
                <div
                  className={
                    "relative mx-auto grid grid-cols-1 md:grid-cols-3 gap-3 max-w-5xl"
                  }
                >
                  <div className={"relative"}>
                    <Link to="/testimonials/mark">
                      <div
                        className={
                          "aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-2"
                        }
                      >
                        <div className="aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-2">
                          <AdvancedImage
                            plugins={[responsive({ steps: 200 })]}
                            cldImg={largeScreen ? Motorcycle : MotorcycleMobile}
                            alt="ARITAE Player riding a motorcycle through the desert fast."
                            className="h-full w-full object-cover"
                          />
                        </div>
                        <div
                          className={"absolute inset-0 opacity-50 bg-stone-500"}
                        />
                        <div>
                          <h1
                            className={
                              "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center text-xl font-bold"
                            }
                          >
                            Mark
                          </h1>
                        </div>
                      </div>
                    </Link>
                    <p className="mt-2 block text-xs text-black text-left pointer-events-none max-w-sm md:max-w-lg lg:max-w-none">
                      “To make a long story short, ARITAE helped our son with
                      his motocross training plan. Afterward, he clinched the
                      title with one race to go. I truly feel ARITAE's game
                      plan, implemented with our son’s coach. I feel grateful to
                      ARITAE"
                    </p>
                    <p className="mt-2 block text-xs font-bold text-black text-left pointer-events-none max-w-sm md:max-w-lg lg:max-w-none">
                      - ARITAE Father, Mark
                    </p>
                  </div>

                  <div className={"relative"}>
                    <Link to="/testimonials/julie-m">
                      <div
                        className={
                          "aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-2"
                        }
                      >
                        <div className="aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-2">
                          <AdvancedImage
                            plugins={[responsive({ steps: 200 })]}
                            cldImg={largeScreen ? Julie : JulieMobile}
                            alt="Juile Moore, an past aritae player who is graduating college!"
                            className="h-full w-full object-cover"
                          />
                        </div>
                        <div
                          className={"absolute inset-0 opacity-50 bg-stone-500"}
                        />
                        <div>
                          <h1
                            className={
                              "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center text-xl font-bold"
                            }
                          >
                            Chris
                          </h1>
                        </div>
                      </div>
                    </Link>

                    <p className="mt-2 block text-xs text-black text-left pointer-events-none max-w-sm md:max-w-lg lg:max-w-none">
                      “The area in which I have seen the most substantial
                      difference is how Chris approached school and homework. He
                      is much more accountable and has become more organized and
                      methodical in how he approached both his schoolwork and
                      teachers"
                    </p>
                    <p className="mt-2 block text-xs font-bold text-black text-left pointer-events-none max-w-sm md:max-w-lg lg:max-w-none">
                      - ARITAE Mother, Julie
                    </p>
                  </div>

                  <div className={"relative"}>
                    <Link to="/testimonials/bonnie-and-richard">
                      <div
                        className={
                          "aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-2"
                        }
                      >
                        <div className="aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-2">
                          <AdvancedImage
                            plugins={[responsive({ steps: 200 })]}
                            cldImg={largeScreen ? Richard : RichardMobile}
                            alt="Colin playing hocket with aponent. He is a past aritae player with a testimonial."
                            className="h-full w-full object-cover"
                          />
                        </div>
                        <div
                          className={"absolute inset-0 opacity-50 bg-stone-500"}
                        />
                        <div>
                          <h1
                            className={
                              "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center text-xl font-bold"
                            }
                          >
                            Justin
                          </h1>
                        </div>
                      </div>
                    </Link>

                    <p className="mt-2 block text-xs text-black text-left pointer-events-none max-w-sm md:max-w-lg lg:max-w-none">
                      “Since his work with ARITAE, we have seen Justin become
                      more conscious of his choices. He spends his down-time
                      shooting baskets, working out, or shooting pucks or
                      lacrosse balls at the net. He seems more comfortable with
                      himself."
                    </p>
                    <p className="mt-2 block text-xs font-bold text-black text-left pointer-events-none max-w-sm md:max-w-lg lg:max-w-none">
                      - Richard (Father of hockey player in picture)
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* END Testimonials Section */}

            {/* START Aritae Paragraph */}
            <div className={"relative py-24 bg-mustard bg-opacity-10"}>
              <div
                className={
                  "flex flex-col mx-auto px-16 items-center justify-center space-y-10"
                }
              >
                <p
                  className={
                    "text-slate-600 font-bold text-4xl max-w-2xl text-center"
                  }
                >
                  ARITAE coaches are carefully selected. They are committed to
                  guiding young people to unleash their potential.
                </p>
              </div>
            </div>
            {/* END Aritae Paragraph */}

            {/* START Coach Pairing Section */}
            <div className="">
              <div className={"max-w-7xl mx-auto py-12 sm:my-24 px-16"}>
                <div
                  className={"relative grid grid-cols-1 lg:grid-cols-2 gap-10"}
                >
                  {/* First section */}
                  <h1 className="contents lg:hidden text-center tracking-tight text-4xl text-slate-600">
                    <span className="block pt-24">
                      THE<span className="font-extrabold"> PLATFORM</span>
                    </span>
                  </h1>
                  <div
                    className={
                      "relative h-full w-full max-w-sm md:max-w-lg lg:max-w-none mx-auto"
                    }
                  >
                    <div className={"relative h-full w-full"}>
                      <div
                        className={
                          "absolute inset-3 -right-3 -bottom-3 bg-slate-600"
                        }
                      />
                      <div
                        className={
                          "relative h-full w-full justify-center items-center"
                        }
                      >
                        <AdvancedImage
                          plugins={[responsive(200)]}
                          cldImg={App}
                          alt="ARITAE Coaches and Players talking with each other over a video call. They are having fun."
                          className="h-full w-full object-cover object-top aspect-square"
                        />
                      </div>
                    </div>
                  </div>
                  {/* Second section */}
                  <div
                    className={
                      "relative flex justify-center items-center -mt-16 md:mt-0 md:p-10"
                    }
                  >
                    <div>
                      <h1 className="text-left text-3xl md:text-4xl pt-12 font-extrabold tracking-tight">
                        <span className="block text-slate-600">
                          ARITAE ACADEMY WILL PAIR YOUR CHILD WITH AN ARITAE
                          COACH
                        </span>
                      </h1>
                      <br />
                      <p className="text-left font-serif text-black md:text-md lg:text-xl">
                        ARITAE Coaches will guide their Players utilizing our
                        mobile platform to accomplish their personal goals in
                        school, sports, activities, and beyond.
                      </p>
                      <br></br>
                      <div className={"flex flex-row gap-10"}>
                        <href
                          a={"/academy/coaches"}
                          className={
                            "flex flex-row items-center gap-5 hover:gap-3"
                          }
                        >
                          <p
                            className={
                              "text-slate-600 font-bold underline underline-offset-4 decoration-2 decoration-slate-600 hover:underline-offset-2"
                            }
                          >
                            Meet the ARITAE Coaches
                          </p>
                        </href>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* END Coach Pairing Section */}

            {/* START ARITAE COACH */}
            <div
              className={
                "grid grid-cols-1 md:grid-cols-2 gap-10 px-4 md:px-16 max-w-7xl mx-auto py-12 lg:py-24"
              }
            >
              {/* First section */}
              <Scene
                duration={durationScrollMagic}
                pin={{ pushFollowers: true }}
                triggerHook={0}
                offset={-20}
                enabled={largeScreen}
              >
                <div className="bg-slate-600 p-10 pb-36 space-y-5">
                  <h1 className="text-white text-4xl">
                    ARITAE COACHES WILL{" "}
                    <span className="font-extrabold">GUIDE</span> YOUR CHILD{" "}
                    <span className="font-extrabold">THROUGH</span> THE{" "}
                    <span className="font-extrabold">5-MODULES</span> OF THE
                    ARITAE SELF-LEADERSHIP ACADEMY.
                  </h1>
                </div>
              </Scene>

              {/* Second section */}
              <Scene duration={1700} triggerHook={0}>
                {(progress) => (
                  <div className={"relative"}>
                    <div
                      className={
                        "flex flex-col max-w-sm ml-10 mt-10 space-y-32"
                      }
                    >
                      <h1 className="text-left text-2xl font-extrabold tracking-tight sm:text-xl lg:text-2xl">
                        <span className="block text-slate-600">
                          ENCOURAGE SELF-DISCOVERY, THEIR WHO, WHAT AND WHY{" "}
                        </span>
                      </h1>

                      <h1 className="text-left text-2xl font-extrabold tracking-tight sm:text-xl lg:text-2xl">
                        <span className="block text-slate-600">
                          FIND THE CLARITY, FOCUS AND POWER TO UNLEASH THEIR
                          POTENTIAL
                        </span>
                      </h1>
                      <h1 className="text-left text-2xl font-extrabold tracking-tight sm:text-xl lg:text-2xl">
                        <span className="block text-slate-600">
                          GUIDE YOUR CHILD TO DETERMINE THE ACTIONS, OBSTACLES
                          AND SOLUTIONS TO PREPARE THEI PERSONAL PLANS{" "}
                        </span>
                      </h1>
                      <h1 className="text-left text-2xl font-extrabold tracking-tight sm:text-xl lg:text-2xl">
                        <span className="block text-slate-600">
                          HOLD YOUR CHILD ACCOUNTABLE FOR COMPLETING THEIR
                          MODULES AND EXECUTING THEIR FINAL PLAN
                        </span>
                      </h1>

                      <h1 className="text-left text-2xl font-extrabold tracking-tight sm:text-xl lg:text-2xl">
                        <span className="block text-slate-600">
                          INSPIRE YOUR CHILD TO LIVE A LIFE FILLED WITH LOVE,
                          HAPPINESS, SIGNIFICANCE AND SUCCESS
                        </span>
                      </h1>
                    </div>
                  </div>
                )}
              </Scene>
            </div>
            {/* END ARITAE COACH */}

            {/* START Animation */}
            <div className="bg-mustard bg-opacity-10">
              <div className="mx-auto py-24 px-4 md:px-16 max-w-7xl ">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-24">
                  <div className="flex flex-col justify-center">
                    <h2
                      className={
                        "text-slate-600 text-left text-2xl font-bold uppercase"
                      }
                    >
                      The ARITAE Self-Leadership Academy Program is built on a{" "}
                      <span className="underline decoration-mustard decoration-4 underline-offset-4">
                        {" "}
                        5 module{" "}
                      </span>
                      course that guides your child towards success.
                    </h2>
                    <div className="flex flex-col items-start pt-5 space-y-3 max-w-md">
                      <div className="flex flex-row space-x-5">
                        <p className="font-bold text-2xl text-navy">01</p>
                        <p className="font-serif">
                          <strong>Discovery.</strong> Deep thought and
                          self-reflection about life are the foundation for all
                          to come. Your child will be guided toward a deeper
                          sense of self-awareness that helps them get in touch
                          with what is real in their heart.
                        </p>
                      </div>
                      <div className="flex flex-row space-x-5">
                        <p className="font-bold text-2xl text-mustard">02</p>
                        <p className="font-serif">
                          <strong>Focus.</strong> Your child will use what they
                          discovered to formulate their vision and purpose of{" "}
                          <strong>who</strong> they are today,{" "}
                          <strong>what</strong> they want in the future, and a
                          better understanding of their <strong>why</strong>.
                        </p>
                      </div>
                      <div className="flex flex-row space-x-5">
                        <p className="font-bold text-2xl text-maroon">03</p>
                        <p className="font-serif">
                          <strong>Action.</strong> Your child will formalize
                          their set of goals that are in alignment with their
                          stated purpose and vision.
                        </p>
                      </div>
                      <div className="flex flex-row space-x-5">
                        <p className="font-bold text-2xl text-denim">04</p>
                        <p className="font-serif">
                          <strong>Strategy.</strong> Your child will design{" "}
                          <strong>how</strong> they will accomplish each of
                          their stated goals with ARITAE Microplans&trade;.
                        </p>
                      </div>
                      <div className="flex flex-row space-x-5">
                        <p className="font-bold text-2xl text-forest">05</p>
                        <p className="font-serif">
                          <strong>Personal Plan.</strong> Your child will
                          organize the information they discovered and work with
                          their coach to create their Personal Plan to
                          accomplish their goals and find love, happiness,
                          significance and success.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-center order-first lg:order-last">
                    <div className="max-w-xl">
                      <ModuleAnimation />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* END Animation */}

            {/* START Emails Section */}

            <div className="py-12 md:py-24">
              <h2 className="text-4xl text-center text-slate-600 sm:leading-none sm:tracking-tight ">
                WE KEEP YOU <span className="font-extrabold">IN THE LOOP</span>
              </h2>
              <div className={"hidden lg:flex xl:hidden justify-end"}>
                <div
                  className={
                    "relative max-w-6xl min-w-6xl bg-slate-600 h-[30rem] w-9/12"
                  }
                >
                  <AdvancedImage
                    plugins={[responsive({ steps: 200 })]}
                    cldImg={Laptop}
                    alt="older gleeful female aritae parent rejoicing while recieving email notifications from aritae about the progress of her child through our program"
                    className={
                      "hidden lg:block absolute h-80 w-80 object-contain -left-48 bottom-20"
                    }
                  />
                  <div className="flex">
                    <p
                      className={
                        "text-white leading-relaxed text-center lg:mt-20 px-16 py-28 pl-52 xl:pl-80 font-bold text-2xl"
                      }
                    >
                      YOU WILL RECEIVE EMAIL NOTIFICATIONS OF YOUR CHILD'S
                      PROGRESS THROUGHOUT THE PROGRAM.
                    </p>
                  </div>
                </div>
              </div>

              {/* XL Screens */}
              <div>
                <div className="hidden xl:flex py-12 justify-center">
                  <div className="bg-slate-600 mx-8 flex max-w-5xl shadow">
                    <div className="w-1/2">
                      <div className="h-64 bg-cover lg:rounded-lg lg:h-full">
                        <AdvancedImage
                          plugins={[responsive({ steps: 200 })]}
                          cldImg={Laptop}
                          alt="older gleeful female aritae parent rejoicing while recieving email notifications from aritae about the progress of her child through our program"
                          className={"h-full w-full"}
                        />
                      </div>
                    </div>
                    <div className="py-12 px-6 max-w-xl lg:max-w-5xl lg:w-1/2">
                      <p className="mt-4 p-8 text-white leading-relaxed py-20 font-bold text-2xl">
                        YOU WILL RECEIVE EMAIL NOTIFICATIONS OF YOUR CHILD'S
                        PROGRESS THROUGHOUT THE PROGRAM.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Mobile */}
              <div className="relative">
                <div className={"flex lg:hidden justify-center align-middle"}>
                  <div className={"relative bg-slate-600 mx-8 p-8"}>
                    <div className="flex">
                      <span
                        className={
                          "text-white leading-relaxed text-center font-bold text-2xl"
                        }
                      >
                        YOU WILL RECEIVE EMAIL NOTIFICATIONS OF YOUR CHILD'S
                        PROGRESS THROUGHOUT THE PROGRAM.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* END Emails Section */}

            {/* START Pricing Section */}
            <div className="max-w-7xl mx-auto py-12 lg:py-24 px-4 bg-white sm:px-6 lg:px-8">
              <h2 className="text-4xl text-center text-slate-600 sm:leading-none sm:tracking-tight ">
                PLANS THAT <span className="font-extrabold">SUIT YOU</span>
              </h2>

              {/* Tiers */}
              <div className="mt-12 max-w-7xl mx-4 md:mx-36 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-3">
                {pricing.tiers.map((tier) => (
                  <div
                    key={tier.title}
                    className="relative max-w-sm md:max-w-lg lg:max-w-none mx-auto bg-gray-100 shadow-sm flex flex-col"
                  >
                    <header className="bg-slate-600 text-4xl text-center font-semibold text-white mb-6 p-10 underline decoration-solid decoration-mustard decoration-4 underline-offset-4">
                      {tier.title}
                    </header>
                    <div className="flex-1 px-8">
                      <p className="mt-6 text-gray-500">{tier.description}</p>

                      {/* Feature list */}
                      <ul className="mt-12 space-y-8 p-4">
                        {tier.features.map((feature) => (
                          <li key={feature} className="flex">
                            <CheckIcon
                              className="flex-shrink-0 w-6 h-6 text-mustard"
                              aria-hidden="true"
                            />
                            <span className="ml-3 text-gray-500 mx-6 font-serif">
                              {feature}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <Link
                      to="/coming-soon"
                      className="bg-slate-600 text-white hover:bg-mustard py-3 px-6 border border-transparent mx-6 text-center font-bold mt-24 mb-6"
                    >
                      {tier.cta}
                    </Link>
                  </div>
                ))}
              </div>
              <p className="font-serif text-center mt-12">
                Scholarships are{" "}
                <Link
                  to="/coming-soon"
                  className="underline underline-offset-2"
                >
                  available
                </Link>{" "}
                for those who qualify.
              </p>
            </div>
            {/* END Pricing Section */}

            {/* START Sign Up Form */}
            <div className={"flex h-screen justify-center items-center mt-24"}>
              <div className="absolute z-10 flex h-screen w-screen overflow-hidden">
                <div className={"absolute inset-0 opacity-75 bg-stone-500"} />
                <AdvancedImage
                  plugins={[responsive({ steps: 200 })]}
                  cldImg={
                    largeScreen ? TeenagerTouchdown : TeenagerTouchdownMobile
                  }
                  alt="ARITAE coach surfing on a wave in the ocean with the sun shining in the background"
                  className="w-full h-full object-cover"
                />
              </div>

              <div className="relative z-20 mx-auto max-w-3xl text-center md:px-5">
                <div className="py-10">
                  <h4 className="text-4xl font-extrabold text-white">
                    IS YOUR CHILD READY TO UNLEASH THEIR POTENTIAL?
                  </h4>
                </div>

                <form
                  className="space-y-6 row-auto py-5 p-4  max-w-3xl xs:m-12 md:m-0"
                  action="#"
                  method="POST"
                  onSubmit={handleSubmit}
                >
                  <div className="mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center justify-between">
                      <div className="mt-1 md:col-span-2">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          placeholder="Email"
                          required
                          className="text-center md:text-left w-full px-5 py-3 text-gray-500 border-transparent placeholder-gray-500 bg-gray-100 font-sm md:font-md"
                        />
                      </div>

                      <div className="mt-1">
                        {loading ? (
                          <LoadingButton type={"email"} />
                        ) : (
                          <button
                            type="submit"
                            className="w-full flex justify-center px-5 py-3 border border-transparent rounded shadow-sm text-sm text-white font-bold bg-black hover:bg-gray-800"
                          >
                            SIGN UP
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/* END Sign Up Form */}
            </div>
          </div>
        </Controller>
      </div>
      <Footer />
    </div>
  );
}
